<template>
  <div style="overflow: hidden">
    <a-carousel>
      <div class="empty-layer">
        <img
          src="@/assets/image/public/logistics-banner.png"
          style="width: 100%"
        />
      </div>
    </a-carousel>
    <a-row type="flex" justify="center" :gutter="56" style="margin-top: 30px">
      <a-col style="margin-bottom: 70px;">
        <a-card class="card-item">
          <a-row type="flex" justify="center" align="middle" :gutter="16">
            <a-col
              ><img src="@/assets/image/public/l1-2.png" style="width: 100px"
            /></a-col>
            <a-col
              ><p class="card-title">
                {{ $t("public.card_title_1") }}
              </p></a-col
            >
          </a-row>
          <p class="card-subtitle">
            <a-space :size="10">
              <span>{{ $t('public.card_subtitle_1') }}</span>
              <span>{{ $t('public.card_subtitle_2') }}</span>
              <span>{{ $t('public.card_subtitle_3') }}</span>
              <span>{{ $t('public.card_subtitle_4') }}</span>
              <span>{{ $t('public.card_subtitle_5') }}</span>
              <span>{{ $t('public.card_subtitle_6') }}</span>
            </a-space>
          </p>
          <span class="card-text">{{ $t("public.about_banner_text_03") }}</span>
        </a-card>
      </a-col>
      <a-col style="margin-bottom: 70px">
        <a-card class="card-item">
          <a-row type="flex" justify="center" align="middle" :gutter="16">
            <a-col
              ><img src="@/assets/image/public/l1-1.png" style="width: 100px"
            /></a-col>
            <a-col
              ><p class="card-title">
                {{ $t("public.card_title_2") }}
              </p></a-col
            >
          </a-row>
          <p class="card-subtitle">
            <a-space :size="10">
              <span>{{ $t('public.card_subtitle_1') }}</span>
              <span>{{ $t('public.card_subtitle_7') }}</span>
              <span>{{ $t('public.card_subtitle_8') }}</span>
            </a-space>
          </p>
          <span class="card-text">{{ $t("public.about_banner_text_04") }}</span>
        </a-card>
      </a-col>
    </a-row>
    <div style="text-align: center; font-weight: bold; font-size: 18px">
      {{ $t("public.se_logistics") }}{{ $t("public.core_advantages") }}
    </div>
    <a-row
      type="flex"
      justify="center"
      style="width: 100%; margin-top: 50px"
      :gutter="24"
    >
      <a-col v-for="(item, index) in advantagesList" :key="index">
        <a-card class="advantages-card">
          <img :src="item.image" alt="" style="width: 80px" />
          <p class="advantages-title">{{ $t(item.title) }}</p>
          <p class="advantages-text">{{ $t(item.text) }}</p>
        </a-card>
      </a-col>
    </a-row>
    <WechatDot />
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { Carousel, Space, Row, Col, Card } from "ant-design-vue";
import CImage from "@/views/components/CImage";
import WechatDot from './components/WechatDot.vue';

export default defineComponent({
  name: "public_se_logistics",
  components: {
    ACarousel: Carousel,
    ASpace: Space,
    ARow: Row,
    ACol: Col,
    ACard: Card,
    CImage,
    WechatDot,
  },
  setup() {
    const advantagesList = ref([
      {
        image: require("@/assets/image/public/3-4.png"),
        title: "public.core_advantages_title_01",
        text: "public.advantages_text_05",
      },
      {
        image: require("@/assets/image/public/5-2.png"),
        title: "public.core_advantages_title_02",
        text: "public.advantages_text_06",
      },
      {
        image: require("@/assets/image/public/2-2.png"),
        title: "public.core_advantages_title_03",
        text: "public.advantages_text_07",
      },
      {
        image: require("@/assets/image/public/1-1.png"),
        title: "public.core_advantages_title_04",
        text: "public.advantages_text_08",
      },
    ]);

    return {
      advantagesList,
    };
  },
});
</script>

<style lang="less" scoped>
.card {
  &-item {
    width: 450px;
    max-width: 100%;
    padding: 20px 50px;
    text-align: center;
    height:100%;
  }
  &-title {
    font-size: 24px;
    font-weight: bold;
  }
  &-subtitle {
    margin-top: 30px;
    margin-bottom: 50px;
    text-align: center;
    font-size: 18px;
    color: rgba(6, 70, 146, 1);
  }
  &-text {
    font-size: 16px;
  }
}

.advantages {
  &-card {
    width: 250px;
    height: 250px;
    margin-bottom: 50px;
    text-align: center;
    box-sizing: border-box;
  }
  &-title {
    margin-top: 10px;
    text-align: center;
    font-size: 18px;
  }
}
</style>